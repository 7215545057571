// ::-webkit-scrollbar {
//     display: none;
// }
// /* Hide scrollbar for IE, Edge and Firefox */
// .table{
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }
.navbar-item img {
  max-height: 2.5rem;
}
.ag-header-cell.ag-focus-managed {
  font-size: 15px;
}
.header-row-column {
  background-color: skyblue;
}

.tabs.is-boxed {
  padding-left: 5vw;
  padding-right: 5vw;
}

.payables {
  height: 40px;
  width: 100px;
  color: white;
  font-weight: bold;
  padding: 8px;
}

.main-content {
  padding-top: 20px;
}
