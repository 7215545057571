.bank-loan-details {
  .detailed {
    background-color: #fff;
    height: 60vh;
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .columns {
    width: 100%;
    text-align: left;
    margin: 0;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .column.is-half {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .column.is-halve {
    margin: 0;
    padding: 0;
  }

  h2 {
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }
  hr {
    width: 1px;
    height: 100%;
    color: #f8f8f8;
  }
  .row {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .buttons {
    position: absolute;
    bottom: 10%;
    right: 10%;
  }
}

@media (max-width: 768px) {
  .bank-loan-details {
    .detailed {
      display: flex;
      flex-direction: column;
      height: 100vh;
      background-color: #fff;
      .buttons {
        position: relative;
        bottom: 2%;
        right: 0%;
        margin-top: 30px;
      }
    }
  }
}
