
.invoice_amount {
    background-color: #c6e3f8;
}
.debit_amount {
    background-color: #c6e3f8;
}
.credit_amount {
    background-color: #c6e3f8;
}
.advance_amount{
    background-color: #c6e3f8;
}