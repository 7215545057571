.faq_container {
  .question {
    font-weight: 600 !important;
    padding: 10px 0;
    align-items: center;
  }

  .css-o4b71y-MuiAccordionSummary-content {
    align-items: center;
  }

  .faq_title {
    text-align: center;
    font-size: 24px;
    color: #531c4c;
    font-weight: 800;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 2px 16px 16px !important;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
    // height: 40px !important;
  }

  .MuiPaper-root.MuiAccordion-root::before {
    background-color: transparent !important;
  }
}
