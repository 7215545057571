@font-face {
    font-family: 'WorkSans';
    font-style: normal;
    font-weight: normal;
    src: local('WorkSans'), url(./../../Fonts/WorkSans-VariableFont_wght.ttf ) format('truetype');
}
@font-face {
    font-family: 'WorkSansItalic';
    font-style: normal;
    font-weight: normal;
    src: local('WorkSansItalic'), url(./../../Fonts/WorkSans-Italic-VariableFont_wght.ttf) format('truetype');
}
*{
	margin: 0px;
    padding: 0px;
    font-family:'WorkSans'; 
    font-size: 16px;
}

// $primary-color: #4072E3;
$primary-color: rgb(83,28,76);
// $text-color: #808080;
$text-color: rgb(83,28,76);
$background-color: #F0F0F7;

.login-page{
    // display: flex;
    height: 100vh;
}
.login-left{
    // width: 40vw;
    height: 100vh;
    // min-height: 500px;
    background-color:$primary-color ;
    z-index: 10;
    display: flex;
    align-items: center;
}
.login-left img{
    width: 38vw;
    margin-left: 7vw;
    z-index: 20;
}

.login-right-height{
    height: 100vh;
    background: #ecece3;
    width: 100%;
}

.login-right{ 
    text-align: center;
    // width: 60vw;
    height: 100vh;
    background-color: $background-color;
    display: flex;
    align-items: center;

    .signuplogo{
        width: 30%;
    }
}

.login-right-center>img{
    width: 222px;
}
.enter-info-text{
    font-size: 18px;
    line-height: 21px;
    color: $text-color;
    margin-top: 20px;
    margin-bottom: 45px;
}
.login-right-center {
    height: 92vh;
    min-height: 500px;
    display: flex;
    align-items: center;
}
.center-box{
    display: block;
    margin: auto;
}

.input-box{
    height: 50px;
    border:1px solid $primary-color;
    padding-left: 20px;
    font-size: 16px;
    background-color: $background-color;
    font-family: 'WorkSans';
}
.input-box::-webkit-input-placeholder {
    font-family: 'WorkSans';
}
  
.input-box:-ms-input-placeholder {
    font-family: 'WorkSans';
}
.input-box:hover{
    border: 1px solid $primary-color;
}

.login-button{
    background-color:$primary-color;
    color: white !important;
    height: 50px;
    width: 9vw;
    min-width: 100px;
    font-size: 18px;
    border-radius: 6px;
    margin: auto;
    margin-top: 20px;
}
.login-button:hover{
    color: white;
    font-weight: bold;
} 
.help{
    font-size: 16px;
    margin-top: 10px;
    color: $primary-color;
}

#flash-msg{
    right: 0px !important;
    position: relative !important;
    display: none;
}

#flash-msg2{
    right: 0px !important;
    position: relative !important;
    display: none;
}

#eye{
    margin-top: 16px;
    margin-left: -32px;
    z-index: 10;
}

.contact{
    line-height: 19px;
    color: $text-color;  
}
.resetlogo{
    width: 50%;
}
.reset_user_btn{
    width: 100px !important;
    margin-right: 20px !important;
}
.reset_company_btn{
    width: 100px !important;
}

@media(max-width:600px){
    .login-page{
        display: block;
        .login-left{
            width: 100vw;
            min-height: 200px;
            height: 300px;
        }
        .login-right{ 
            width: 100vw;
            // min-height: 300px;
            height: 1280px;
            background-color: $background-color;
            .login-right-center{
                min-height: 300px;
                height: 500px;
            }
            .center-box{  
                margin-top: 30px;
            }
            .signuplogo{
                width: 60%;
            }
           
        }
    }
    .resetlogo{
        width: 80%;
    }
}