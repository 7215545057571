.tier2-bulk-upload {
    .column {
        padding-left: 2vw;
    }
    .file.has-name .file-cta {
        color: white;
        border-radius: 5px;
    }
    .errors {
        h1 {
            font-weight: bold;
        }
        p {
            color:red
        }
    }
}