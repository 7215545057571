tbody > tr:nth-of-type(even) {
  font-family: "WorkSans";
  background-color: #ffffff;
  transition: background-color 0.5s ease;
}

tbody > tr:nth-of-type(odd) {
  font-family: "WorkSans";
  background-color: #eaf0f9;
  transition: background-color 0.5s ease;
}

// 1st Phase Rules Table.main-table , table-head, table-head column, sticky left-right.
// 2nd Phase Rules Non Macintosh Devices Custom Scrollbar
// 3rd Phase Rules Table Head, sort-icon, filterBox

table.main-table {
  display: flex;
  flex-flow: column;
  height: calc(100% - 50px);
  overflow: auto;
  max-height: 70vh;
  position: relative;

  .column {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0.75rem;
    white-space: nowrap;
  }

  .table-head {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  td {
    &:not(.noHide),
    &:not(:has(.noHide)) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:has(.noHide) {
      overflow: auto;
      text-overflow: initial;
    }

    &:not(.noHide) > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > *.noHide {
      white-space: nowrap;
      overflow: auto;
      text-overflow: initial;
    }
  }

  .stickyLeft,
  .stickyRight {
    position: sticky;
    left: var(--data-val, 0);
    z-index: 2;
  }

  .stickyRight {
    left: unset;
    right: var(--data-val, 0);
  }

  td.stickyLeft,
  td.stickyRight {
    background-color: inherit;
  }

  .blankFolder {
    height: 4em;
    width: 4em;
    color: #8f9eb4;
    transform: scale(1.3);
  }
}

@mixin medium-scrollbar {
  height: 16px;
  width: 16px;
  background: transparent;
}

@mixin medium-scrollbar-corner {
  height: 16px;
  width: 16px;
  background: transparent;
}

@mixin medium-scrollbar-thumb {
  background: content-box currentColor;
  border: 6px solid transparent;
  border-radius: 8px;
  color: #d5dce3;
  min-height: 60px;
  min-width: 60px;
  &:hover {
    color: #7f8388;
    transition: all 0.2s ease-out;
  }
  &:active {
    color: #5f6368;
    transition: all 0.2s ease-out;
  }
}

@mixin medium-scrollbar-track {
  background: transparent;
}

@mixin medium-scrollbar-button {
  height: 0;
  width: 0;
}

@mixin medium-scrollbar-color-width {
  scrollbar-color: #d5dce3 transparent;
  scrollbar-width: thin;
}

@mixin component-scrollbar {
  &::-webkit-scrollbar {
    @include medium-scrollbar;
  }

  &::-webkit-scrollbar-corner {
    @include medium-scrollbar-corner;
  }

  &::-webkit-scrollbar-thumb {
    @include medium-scrollbar-thumb;
  }

  &::-webkit-scrollbar-track {
    @include medium-scrollbar-track;
  }

  &::-webkit-medium-scrollbar-button {
    @include medium-scrollbar-button;
  }

  & {
    @include medium-scrollbar-color-width;
  }
}

.NonMac {
  & :not(.NonMacBody)::-webkit-scrollbar {
    @include medium-scrollbar;
  }

  & :not(.NonMacBody)::-webkit-scrollbar-thumb {
    @include medium-scrollbar-thumb;
  }

  & :not(.NonMacBody)::-webkit-scrollbar-track {
    background: transparent;
  }

  // Add classes to add scrollbar directly
  .MuiDialogContent-root,
  .modal-card-body,
  .MuiContainer-root {
    @include component-scrollbar;
  }
}

// table tr:hover {
//   background-color: #f5f9fc;
// }
.table_select_ui {
  padding: 10px;
  margin-left: 15px;
  border-radius: 20px;
  border-color: gray;
  min-height: 25px;
  input {
    padding: 10px;
    border-radius: 20px;
    border-color: gray;
    min-height: 25px;
    max-height: 25px;
  }
}

svg {
  transition: all 0.25s ease-out;
}

.rotated {
  transform: rotate(180deg);
}

thead tr:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  & > :last-child {
    border-top-right-radius: 12px;
  }
  & > :first-child {
    border-top-left-radius: 12px;
  }
}

tbody tr:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  & > :last-child {
    border-bottom-right-radius: 12px;
  }
  & > :first-child {
    border-bottom-left-radius: 12px;
  }
}

table th {
  font-family: "WorkSans";
  text-align: center;
  background-color: #531c4c;
  color: white;
  padding: 12px 10px 12px 0px;
  border-bottom: 1px solid #53526c;
  overflow-wrap: break-word;
  position: relative;
  cursor: pointer;

  &:has(.noSort),
  &.noSort {
    cursor: default;
  }

  .header .sort-icon {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &:hover .sort-icon,
  .header:hover .sort-icon {
    opacity: 1;
  }

  &:hover .filterBox {
    opacity: 1;
  }

  .filterBox:has(input.focused, input.shown) {
    opacity: 1;
  }

  .filterBox svg path {
    transition: all 0.5s ease-out;
  }
  .filterBox:has(input.focused) svg path {
    fill: #4db6ac;
  }

  .filterBox {
    position: absolute;
    bottom: -15px;
    background: #fafaff;
    padding: 0px 8px;
    width: calc(100% - 10px);
    border-radius: 12px;
    opacity: 0;
    z-index: 2;
    transition: all 0.2s ease-out;
    box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.3);
  }
  .filterBox span {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    padding: 2px;
  }

  .filterBox input {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
  }
}

table td {
  padding: 11px;
  font-family: "WorkSans";
  text-align: center;
  color: black;
  overflow-wrap: break-word;
}
// .next_prevbtn{
//   color: #27b27c !important;
// }
.summary_wrapper {
  padding: 20px;
  overflow: initial !important;
  overflow-x: scroll !important;

  .summary_table tbody > tr {
    align-items: center;
    .table_subrow {
      padding-left: 30px;
    }
  }
  .summary_table_footer {
    padding-left: 10px;
    margin-top: 15px;
    text-align: center;
  }
}
.pdfTableHeading {
  padding: 10px 40px;
  height: 40px;
  border-left: 0.1px solid grey;
  border-right: 0.1px solid grey;
}
.pdfTableHeading_border {
  border: 0.1px solid gray;
}
.errorMessage {
  color: black !important;
  background-color: white !important;
  border-left: 0.1px solid grey;
  border-right: 0.1px solid grey;
  padding: 10px;
  font-weight: 400;
}
.table_fixed_div {
  overflow-x: auto;
  overflow-y: auto;
  height: fit-content;
  max-height: 55vh;
  table thead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
}

.header_node {
  width: 100%;
  display: flex;
  justify-content: center;
}
