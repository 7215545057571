/* fa icon css */
/* @import url("https://cdn.jsdelivr.net/gh/hung1001/font-awesome-pro@4cac1a6/css/all.css"); */

/* fa 6 icon css  */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* zoom: 0.9; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: none;
  pointer-events: none;
}

main>div,
main>div>div {
  animation: fade-in 0.5s ease-out;
}

.fade_in {
  animation: fade-in 0.5s ease-out;
}

.fade_up {
  animation: fade-up 0.5s ease-out var(--delay, 0s);
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(+10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


/* Error Boundary UI */

:root {
  --side: 2px;
  --x-move: 60px;
  --y-move: 60px;
  --last-x: 00px;
  --last-y: 00px;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  /* background: linear-gradient(45deg, #3f51b5, #ef5350); */
  background: #efefff;
}

.card {
  display: grid;
  grid-template-columns: 500px 500px;
  min-height: 60vh;
  width: 1000px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: white;
}

.card .left {
  display: grid;
  grid-template-columns: auto;
  row-gap: 50px;
  justify-content: center;
  align-content: center;
  padding: 48px;
}

.left .ErrorHeading {
  font-size: 3rem;
  font-weight: bold;
}

.left .cutout {
  position: absolute;
}

.card .right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.right .background {
  display: grid;
  background: #473164;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  align-content: center;
  justify-content: center;
}

.background .star {
  display: block;
  position: absolute;
  opacity: 0.5;
  height: var(--side);
  width: var(--side);
  border-radius: 50px;
  background: white;
  animation: fade-away 0.5s ease-out calc(var(--delay)) 5 alternate forwards;
}

@keyframes fade-away {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.background .planet {
  background: #543368;
  height: 500px;
  width: 500px;
  position: absolute;
  right: 0px;
  align-self: center;
  border-radius: 100%;
  transform: translateX(50%);
}

.background .astronaut {
  position: absolute;
  height: 200px;
  animation: rotate-move 9s linear infinite normal forwards;
  z-index: 3;
}

@keyframes rotate-move {
  from {
    transform: rotate(0);
    top: var(--last-y);
    left: var(--last-x);
    transform-origin: center;
  }

  to {
    transform: rotate(360deg);
    top: var(--y-move);
    left: var(--x-move);
    transform-origin: center;
  }
}

.btn {
  height: 50px;
  width: 220px;
  padding: 4px 8px;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  border: none;
  outline: none;
  color: white;
  background-color: #0069d9;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}