$textBlackGray: #333;
$iconBlue: #4070f4;
$nakadViolet: #531c4c;
$textGray: #777;

.flex {
  display: flex;
  align-items: center;
}
.nav_image {
  display: flex;
  min-width: 55px;
  justify-content: center;
  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 310px;
  background: #fff;
  padding: 15px 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  z-index: 1000;
  user-select: none;

  &:has(.overflowing) {
    padding-right: 5px;
  }

  * {
    transition: all 0.25s ease;
  }

  &:not(.close) {
    box-shadow: 0 0 20px 20px rgb(0 0 0 / 20%);
  }
}
.sidebar.close {
  width: calc(56px + 28px);
  .logo_name {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0s ease !important;
  }
  #lock-icon {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0s ease !important;
  }
  #sidebar-close {
    opacity: 0;
    pointer-events: none;
  }
  .text_title,
  .link span {
    opacity: 0;
  }
  .line {
    opacity: 1;
  }
}
.logo_items {
  gap: 8px;
  justify-content: space-between;
}
.logo_name {
  font-size: 22px;
}
.hoverable {
  .logo_name {
    color: $textBlackGray;
    transition: opacity 0.2s ease-out 0.2s;
  }
  #lock-icon {
    transition: opacity 0.2s ease-out 0.2s !important;
  }
}
#lock-icon {
  padding: 10px;
  color: $iconBlue;
  font-size: 25px;
  cursor: pointer;
  margin-left: -4px;
  transition: all 0.3s ease;
  position: relative;
  right: -26px;
  transform: rotate(45deg);
}
#sidebar-close {
  padding: 10px;
  color: $iconBlue;
  font-size: 25px;
  cursor: pointer;
  margin-left: -4px;
  transition: all 0.3s ease;
  position: relative;
  right: -26px;
  transform: rotate(45deg);
  display: none;
  color: $textBlackGray;
}
.menu_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  overflow-y: auto;
  height: calc(100% - 100px);
  position: relative;

  scrollbar-color: #d5dce3 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0px !important;
    width: 8px !important;
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    height: 16px;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: content-box currentColor !important;
    border: 3px solid transparent !important;
    border-radius: 8px;
    color: transparent !important;
    min-height: 60px;
    min-width: 60px;
    &:hover {
      color: #7f8388 !important;
      background: content-box currentColor !important;
      transition: all 0.2s ease-out;
    }
    &:active {
      color: #5f6368 !important;
      background: content-box currentColor !important;
      transition: all 0.2s ease-out;
    }
  }
  &::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f7;
  }

  &:hover::-webkit-scrollbar-thumb {
    color: #d5dce3 !important;
  }
}
ul.menu_group {
  padding: 4px 2px;
  margin: 4px 0;
  background: transparent;
  border-radius: 8px;

  &:hover,
  &:has(.active) {
    background: rgba(84, 28, 76, 0.1) !important;
  }
}
.MuiAccordionSummary-root {
  border-radius: 8px !important;
}
.menu_title {
  position: relative;
  height: 50px;
  width: 55px;
  color: $nakadViolet;
  font-weight: 500;
  .text_title {
    transition: all 0.3s ease;
    white-space: nowrap;
  }
  i {
    height: 50px;
    min-width: 55px;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  svg {
    height: 24px;
    min-width: 55px;
  }
}

.overflowing {
  i,
  svg {
    min-width: 48px !important;
  }
}

.line {
  position: absolute;
  left: 20px;
  height: 3px;
  width: 20px;
  border-radius: 25px;
  background: #aaa;
  transition: all 0.3s ease;
  opacity: 1;
}
.MuiAccordionSummary-expandIconWrapper {
  opacity: 1;
  transition: all 0.15s ease-out;
}
.sidebar.sidebar.close {
  .MuiAccordionSummary-expandIconWrapper {
    opacity: 0;
    transition: all 0.2s ease-out;
  }
}
.list_item {
  list-style: none;
  margin: 2px 0;
}
.link {
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 8px;
  color: $textGray;
  margin: 0 2px;
  transition: all 0.25s ease, color 0s ease;
  height: 50px;
  &:hover {
    color: #fff;
    background-color: $nakadViolet;
    svg {
      fill: currentColor !important;
    }
  }
  svg {
    transition: all 0.25s ease, fill 0s ease, color 0s ease;
    * {
      transition: all 0.25s ease, fill 0s ease, color 0s ease;
    }
  }
  span {
    white-space: nowrap;
  }
  i {
    height: 50px;
    min-width: 55px;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 50px;
  }
  svg {
    height: 24px;
    min-width: 55px;
  }
}
.link.active {
  color: #fff;
  background-color: $nakadViolet;
  svg {
    fill: currentColor !important;
  }
}
.sidebar_profile {
  gap: 15px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  .name {
    font-size: 18px;
    color: $textBlackGray;
  }
  .email {
    font-size: 15px;
    color: $textBlackGray;
  }
}

#sidebar-open {
  font-size: 30px;
  color: $textBlackGray;
  cursor: pointer;
  margin-right: 20px;
  display: none;
}
.search_box {
  height: 46px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #aaa;
  outline: none;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 18px;
  color: $textBlackGray;
}

.AccountCircle {
  position: relative;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorcircle {
  display: inline-block;
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 120px;
  background-size: 120px 120px;
  background-position: 120px 0, 120px 120px, 0 0, 0 120px;
  background-repeat: no-repeat;
  background-image: linear-gradient(135deg, #ff0000 7%, #ffff00 59.5%, #80ff00 93%),
    linear-gradient(45deg, #00ffff 7%, #00ff00 59.5%, #80ff00 93%),
    linear-gradient(-135deg, #ff0000 7%, #ff00ff 59.5%, #7f00ff 93%),
    linear-gradient(-45deg, #00ffff 7%, #0000ff 59.5%, #7f00ff 93%);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.4);
}

.colorcircle:after {
  content: "";
  display: block;
  position: absolute;
  width: 192px;
  height: 192px;
  top: 24px;
  left: 24px;
  border-radius: 96px;
  background: #fff;
}

.br_32 {
  border-radius: 32px !important;
}

.p_24 {
  padding: 24px !important;
}

.hue-wheel {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-blend-mode: screen;
  position: absolute;
}

.hue-wheel-1 {
  background-image: conic-gradient(#cf2c5b, #f6c13a, #27b27c, #38bfe8, #531c4c, #531c4c, #cf2c5b),
    radial-gradient(#fff 60%, transparent calc(50% + 1px));
}

a:hover {
  color: #06f;
}

.NonMacBody {
  scrollbar-color: #d5dce3 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 16px;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    height: 16px;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: content-box currentColor;
    border: 6px solid transparent;
    border-radius: 8px;
    color: #d5dce3;
    min-height: 60px;
    min-width: 60px;
    &:hover {
      color: #7f8388;
      transition: all 0.2s ease-out;
    }
    &:active {
      color: #5f6368;
      transition: all 0.2s ease-out;
    }
  }
  &::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f7;
  }
}
