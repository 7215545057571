.actiontop {
  display: flex;
  padding-top: 2rem;
}
.main-content {
  padding-top: 20px;
}
.tier-2-action {
  margin-top: 0px;
  margin: auto;
}
.Tier-2-Invoice {
  margin: auto;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  border-radius: 8px;
}
 .mygrid{
  padding-left: 2vw;
  padding-right: 2vw;
}
.ag-header-cell-text {
  font-size: 14px;
  line-height: 16px;
  color: #53526c;
}
.navbar-item img {
  max-height: 2.5rem;
}

.notification {
  position: absolute;
  right: 50px;
  padding: 1rem;
}

.total-benefit {
  position: absolute;
  right: 20px;
  height: 40px;
  padding: 10px;
  font-weight: bold;
  color: white;
}
.table-info {
  margin: auto;
  height: 40px;
  padding: 10px;
  width: 240px;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
  outline: none !important;
  border: none !important;
}
#table-more-info {
  display: none;
  height: 60px;
  width: 400px;
  padding-left: 10px;
  padding-top: 6px;
  border: 1px solid #3298dc;
  margin: auto;
  position: absolute;
  left: 20%;
  font-weight: 800;
}
.moreinfospan {
  margin-left: 9px;
  cursor: pointer;
}

.highlight {
  background-color: rgb(243, 243, 147) !important;
}

@media (max-width: 1120px) {
  .tier-2-action {
    display: none;
  }
}

.grid-header-centered div div {
  justify-content: center;
}

 .modal-card-body{
//   .ag-header-cell-label {
//     justify-content: center;
//  }
  .ag-cell{
   display: flex;
   justify-content: left;
  }
 }

 .invoice_amount {
  background-color: #c6e3f8;
}
.debit_amount {
  background-color: #c6e3f8;
}
.credit_amount {
  background-color: #c6e3f8;
}
.advance_amount{
  background-color: #c6e3f8;
}