.mapping{
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
  }

  .columns{
    text-align: center;
    height: 42px;
  }

  .column{
    font-size: 14px;
    margin : 0 10px;
  }
  .background-blue{
    background-color: #3273dc;
     color: #fff;
  }
  .background-white{
    background-color: #fff;
     border: solid 2px #3273dc;
  }
  .bold{
    font-weight:bold;
  }
  .margin{
    margin: 10px 0;
  }
}
