.mainContainer {
    height: 90vh;
    width: 100%;

    // background-color: blue;
    .container {
        display: flex;
        height: 85vh;
        min-width: 100%;
        padding: 5px;
        justify-content: space-around;

        .bottomHeading {
            .recharts-default-legend {
                opacity: 0;
            }
        }

        .childContainer {
            height: 95%;
            width: 45%;
            padding: 5px;
            background-color: #fff;
            border-radius: 10px;
            // .recharts-responsive-container{
            //     background-color: #000051;
            // }
            // background-color: aqua;

            .title {
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                color: black;
            }

            .subtitle {
                height: 70px;
                width: 95%;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                span {
                    margin-left: 20px;
                    text-align: center;
                    width: 150px;
                    height: 40px;
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    font-weight: bold;
                    outline: #3273DC solid 2px;

                    .dropdown {
                        border: hidden;
                        width: 150px;
                        height: 40px;
                        background: #fff;
                        color: black;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        font-weight: bold;
                        outline: #3273DC solid 2px;
                    }
                }

                .leftSubtitle {
                    margin-left: 3%;
                }
            }

            .barShape {
                border-radius: 10%;
            }
        }

        .doubleGraph {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .subtitle {
                height: 70px;
                width: 95%;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }

            .barShape {
                border-radius: 10%;
            }

            .icon {
                width: 20%;
                height: 74%;
            }
        }
    }

}

.tableContainer {
    height: 75vh;
    width: 100%;

    .innerContainer {
        height: 85%;
        margin-left: 2.75%;
        width: 94.5%;
        background-color: #fff;
        border-radius: 10px;
        .title {
            height: 18%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            font-size: 40px;
            padding-top: 15px;
        }
        .table{
            width: 98%;
            margin-left: 1%;
            display: flex;
            justify-content: center;
        }
    }
}