@font-face {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: normal;
  src: local("WorkSans"),
    url(./../../Fonts/WorkSans-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "WorkSansItalic";
  font-style: normal;
  font-weight: normal;
  src: local("WorkSansItalic"),
    url(./../../Fonts/WorkSans-Italic-VariableFont_wght.ttf) format("truetype");
}
* {
  margin: 0px;
  padding: 0px;
  font-family: "WorkSans";
  font-size: 14px;
}

$primary-color: #27b27c;
$background-color: #f0f0f7;

.navbar {
  height: 70px;
  background-color: white;
}

.main-logo {
  margin-left: 5vw;
}
.navbar-menu {
  // padding-right: 17vw;
  justify-content: flex-end;
}
.navbar-center {
  display: flex;
  //   margin: auto;
}
.navbar-right {
  position: absolute;
  right: 4vw;
  display: flex;
  top: 20px;
}
.navbar-end > img {
  cursor: pointer;
}
.navbar-start > .navbar-item {
  color: #43425d;
}

.navbar-item {
  font-size: 16px;
  //margin-left: 30px;
  // margin-left: 30px;
}
.navbar-item.has-dropdown.is-hoverable {
  border-left: 1px solid #ebebf2;
  height: 28px;
  margin-left: 30px;
  margin-right: 0px;
}
.navbar-link:not(.is-arrowless):after {
  border-color: #a4afb7;
}
.name-full {
  font-size: 13px;
}
.name-first {
  background-color: #e2e2e2;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.name-first > p {
  font-size: 13px;
  color: #4d4f5c;
  margin: auto;
}
.main-content {
  background-color: $background-color;
  height: fit-content;
  min-height: 98vh;
}
.navbar-brand {
  height: 100%;
}

.navbar-item {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}



a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: transparent;
}

.support {
  margin-right: 30px;
  margin-top: 5px;
}

.this-page {
  // font-weight: 600;
  height: auto;
  font-size: 16px;
  line-height: 21px;
  color: #27b27c !important;
  // border-bottom: 3px solid $primary-color;
}

.tabs li {
  background-color: #f5f7fc;
}

.is-active a span {
  font-weight: 600;
  color: white !important;
}

.tabs.is-toggle {
  margin-left: 5vw;
  padding-top: 45px;
}

.tabs.is-toggle a {
  border: 1px solid #27b27c;
  height: 44px;
}
.tabs.is-toggle a:hover {
  border: 1px solid #27b27c !important;
  height: 44px;
}
.tabs.is-toggle a span {
  font-size: 16px;
  color: #27b27c;
}

@media screen and (min-width: 768px) {
  .navbar-brand-centered {
    position: absolute;
    left: 50%;
    display: block;
    width: 160px;
    text-align: center;
    background-color: #eee;
  }
  .navbar > .container .navbar-brand-centered,
  .navbar > .container-fluid .navbar-brand-centered {
    margin-left: -80px;
  }
}

.invoice-approved {
  width: 300px;
}
.ag-header-cell.ag-focus-managed {
  font-size: 15px;
}
.mygrid {
  padding-left: 5vw;
  padding-right: 5vw;
  // height: 20vw;
  width: 100vw;
}
.dont-like {
  text-align: center;
  width: 100vw;
  margin-bottom: 15px;
  margin-top: 15px;
}

.dont-like p {
  color: #4d4f5c;
  font-size: 16px;
  line-height: 19px;
}

.navbar-item img {
  max-height: 2.5rem;
}
.table1 {
  height: 150px;
  margin-left: 8vw;
  margin-right: 8vw;
  text-align: center;
}
.ag-header-row {
  background-color: white;
  border: none;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  border-radius: 8px;
  padding-bottom: 20px;
}
.ag-theme-alpine .ag-header {
  border: none !important;
}

.ag-theme-alpine .ag-row {
  border: 1px solid white !important;
}

.ag-header-cell-text {
  font-weight: bold !important;
  color: #53526c;
}

// .ag-theme-alpine .ag-header-cell-resize {
//     display: none !important;
// }

// .ag-row{
//     max-height: 50px !important;
// }
// .ag-theme-material .ag-cell {
//     max-height: 50px !important;
// }

// .ag-row.ag-row-odd.ag-row-level-0.ag-row-position-absolute.ag-row-focus.ag-row-hover {
//     max-height: 50px !important;
// }

.ag-header-cell-label {
  justify-content: left;
}

.grid-header-centered div div {
  justify-content: center;
}

.grid-header-right div div {
  justify-content: flex-end;
  margin-right: 8px;
}
.grid-header-marginLeft div div {
  margin-left: 51px;
}

.ag-theme-alpine .ag-row-even {
  background-color: #f5f9fc !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: white !important;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.remark-class.ag-cell-value.ag-cell-wrap-text {
  padding-top: 10px;
  line-height: 20px;
}

.is-accept,
.is-cancel {
  height: 30px;
  margin-top: 4px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  font-family: "WorkSans";
}

.is-accept {
  background-color: #29ba74;
}

.is-accept:hover {
  background-color: #48ac23;
  color: white;
  font-weight: bold;
}

.is-cancel {
  background-color: #e71c56;
}

.is-cancel:hover {
  background-color: #f04129;
  color: white;
  font-weight: bold;
}

.modal-card-body{
//   .ag-header-cell-label {
//     justify-content: center;
//  }
.ag-cell{
  display: flex;
  justify-content: center;
 }
}

.invoice_amount {
  background-color: #c6e3f8;
}
.debit_amount {
  background-color: #c6e3f8;
}
.credit_amount {
  background-color: #c6e3f8;
}
.advance_amount{
  background-color: #c6e3f8;
}